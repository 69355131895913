<template>
    <div>
        <div class="d-flex" style="width:100%; height:100%;">
            <OMenuComp
                :listMenu="listMenu"
                :mini="mini"
            />
            <OMenuMobileComp
                :listMenu="listMenu"
            />
            <div :style="style_body" class="app__view-router">
                <router-view/>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { OMenuComp, OMenuMobileComp } from '../../components/organisms'
export default {
    components: {
        OMenuComp,
        OMenuMobileComp
    },
    data: () => ({
        listMenu: [
            {
                icon: 'mdi-calendar-clock-outline',
                title: 'Horarios',
                route: '/website/timetable',
                submenu: []
            },{
                icon: 'mdi-phone',
                title: 'Teléfonos',
                route: '/website/phones',
                submenu: []
            },{
                icon: 'mdi-image-area',
                title: 'Fotografías',
                route: '/website/photographs',
                submenu: []
            },{
                icon: 'mdi-animation',
                title: 'Noticias',
                route: '/website/notices',
                submenu: []
            }
        ]
    }),
    computed: {
        ...mapState('menuCompStore', ['mini', 'style_body']),
    }
}
</script>